import { mapState, mapGetters } from 'vuex'
import config from '../../config'

export const assetMixin = {
  computed: {
    ...mapState({
      groupedAssets: state => state.assets.assets
    }),
    ...mapGetters({
      assetsLoaded: 'assets/assetsLoaded'
    }),
    asset () {
      if (!this.assetsLoaded) return null

      const { id } = this.$route.params
      const asset = this.groupedAssets.find(f => f.Name === id)
      if (asset) return asset
      else this.$router.push(`/${config.ns}`)
    }
  }
}

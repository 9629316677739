<template>
  <div class="w-100">
    <!-- Heading -->
    <pibot-page-heading v-if="asset">
      <span>{{asset.Name}}</span>

      <template #sub-title
                v-if="asset.Description">
        <span>{{asset.Description}}</span>
      </template>

      <template #append>
        <span class="mr-5">Risk Status:</span>
        <pibot-risk-chip
          :risk="asset.Total_risk"
          small
        />
      </template>
    </pibot-page-heading>

    <v-container class="pb-5">
      <!-- PAPI block -->
      <template v-if="features.papi">
        <pibot-asset-papi class="pt-5" />
        <v-divider />
      </template>
      <!-- History block -->
      <template v-if="features.history">
        <pibot-asset-history class="pt-5" />
        <v-divider />
      </template>
      <!-- Codes and Standards block -->
      <template v-if="features.codes">
        <pibot-asset-codes class="pt-5" />
        <v-divider />
      </template>
      <!-- Risk Status block -->
      <template v-if="features.risk">
        <pibot-asset-risk-status class="pt-5" />
        <v-divider />
      </template>
      <!-- Predictions block -->
      <template v-if="features.predictions">
        <pibot-asset-predictions class="pt-5" />
        <v-divider />
      </template>
      <!-- IRM Planning block -->
      <template v-if="features.planning">
        <pibot-asset-planning class="pt-5" />
        <v-divider />
      </template>
      <!-- Attachments block -->
      <template v-if="features.attachments">
        <pibot-asset-attachments class="pt-5" />
        <v-divider />
      </template>
      <!-- Risk Matrix Considerations block -->
      <template v-if="features.matrix">
        <pibot-asset-risk-matrix class="pt-5" />
      </template>
    </v-container>
  </div>
</template>

<script>
import PageHeading from '@/components/typography/headings/PageHeading'
import RiskChip from '@/components/chips/RiskChip'
import config from '../../../config'
import { assetMixin } from '../../../utils/mixins/asset.mixin'

export default {
  name: 'pibot-asset-details',
  mixins: [assetMixin],
  components: {
    'pibot-page-heading': PageHeading,
    'pibot-risk-chip': RiskChip,
    'pibot-asset-attachments': () => import('../../Attachments/components/Attachments'),
    'pibot-asset-papi': () => import('../../PAPI/components/PAPI'),
    'pibot-asset-history': () => import('../../History/components/History'),
    'pibot-asset-codes': () => import('../../Codes/components/Codes'),
    'pibot-asset-risk-status': () => import('../../RiskStatus/components/RiskStatus'),
    'pibot-asset-predictions': () => import('../../Predictions/components/Predictions'),
    'pibot-asset-planning': () => import('../../Planning/components/Planning'),
    'pibot-asset-risk-matrix': () => import('../../RiskMatrix/components/RiskMatrix')
  },
  data () {
    return {
      features: config.features
    }
  }
}
</script>

<template>
  <v-tooltip v-bind="placement" :disabled="!tooltip || ![1,2,3,4,5].includes(risk)">
    <template v-slot:activator="{ on }">
      <v-chip v-bind="$attrs" label :color="color || computedColor" dark class="pibot-risk-chip" :class="{fluid}" v-on="on">
        <span class="text-capitalize"><slot>{{riskText}}</slot></span>
      </v-chip>
    </template>
    <span> {{ severityText }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'pibot-risk-chip',
  props: {
    risk: {
      type: [String, Number]
    },
    color: String,
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
      validator: function (value) {
        // The value must match one of these strings
        return ['top', 'right', 'bottom', 'left'].includes(value)
      }
    }
  },
  computed: {
    riskText () {
      return this.risk || 'N/A'
    },
    computedColor () {
      let risk = this.risk
      let color = 'white darken-4'
      if (typeof risk === 'string') risk = risk.toLowerCase().replace(' ', '-')

      if (risk === 0) return color
      else if (risk === 'very-high' || risk <= 2) color = 'error darken-2'
      else if (risk === 'high' || risk <= 4) color = 'error'
      else if (risk === 'medium' || risk <= 6) color = 'warning darken-1'
      else if (risk === 'low' || risk <= 8) color = 'success'
      else if (risk === 'very-low' || risk <= 10) color = 'success darken-2'

      return color
    },
    fluid () {
      return this.$attrs.fluid !== undefined
    },
    severityText () {
      const risk = this.risk
      if (risk === 1) return 'No further action required.'
      else if (risk === 2) return 'Possibly investigate during next survey.'
      else if (risk === 3) return 'Recommended to investigate.'
      else if (risk === 4) return 'Highly recommended to investigate and review operating pressure (and possibly lower).'
      else return 'Immediate action and investigation required. Review operating pressure (and possibly lower and or shutdown).'
    },
    placement () {
      return { [this.tooltipPlacement]: this.tooltipPlacement }
    }
  }
}
</script>

<style>
.pibot-risk-chip.fluid {
  width: 100%;
  justify-content: center;
}
</style>
